import React, { useEffect, useState } from "react";
import Styles from "../home.module.css";
import {
  Comp1,
  Comp2,
  Comp3,
  Comp4,
  Comp5,
  Comp6,
  HeroSavag,
} from "../../../Assets";
import CarouselPage from "../../Utils/carousel";
import CalendlyWidget from "../CalendlyWidget";

const ScrollText = ({ texts }) => {
  const [visibleIndex, setVisibleIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVisibleIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [texts]);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ position: "relative", width: "100%" }}>
        {texts.map((text, index) => (
          <>
            <div
              key={index}
              style={{
                position: "absolute",
                top: `${
                  ((index - visibleIndex + texts.length) % texts.length) * 100
                }px`,
                transition: "top 0.5s ease-in-out",
                color: "#0078D1",
                display: visibleIndex === index ? "block" : "none",
                width: "100%",
                textAlign:"center"
              }}
              // className="heading"
            >
              {text}
              <div
                style={{
                  height: "15px",
                  width: "100%",
                  background: "rgba(6, 102, 174, 0.20)",
                  position: "relative",
                  bottom: "2vh",
                }}
              ></div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

const HeroSection = () => {
  const textArrays = [
    "Software",
    "Web Application",
    "Mobile Application",
    "Automation",
  ];
  return (
    <div className={Styles.heroBody}>
      <div style={{ width: "100%" }}>
        <spna className="heading" style={{ width: "100%", display: "flex",flexDirection:"column",gap:"1px",justifyContent:"center",alignItems:"center" }}>
          <div>Building</div>
          <ScrollText texts={textArrays} />
          <div style={{marginTop:"5vh",textAlign:"center"}}>That Drives Company Success</div>
        </spna>
        <div style={{ width: "100%", marginTop: "30px" }}>
          <div style={{ zIndex: "1", position: "relative" }}>
            {" "}
            <CarouselPage />{" "}
          </div>
          <img className={Styles.svagImage} src={HeroSavag} alt="savag" />
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: "10%" }}
        >
          <span className={Styles.heroText}>Trusted by Global Clients</span>
          <div className={Styles.clients}>
            {[Comp1, Comp2, Comp4, Comp5].map((res, index) => (
              <img src={res} alt="Trusted companies" />
            ))}
          </div>
          {/* <button className={Styles.themeBtn}>Get A Free Consult</button> */}
          <CalendlyWidget />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
