import React from "react";
import { Vanna, Qualcentric, Tripeur, Lumen } from "../../Assets";
import styles from "./home.module.css";

const FeedBack = () => {
  const clientFeedback = [
    {
      name: "James Matin-Lewis",
      position: "Vanna , Founder",
      image: Vanna,
      Description: "Truly immersive, and commited. All the best!",
    },
    {
      name: "Shrinivas",
      position: "Qualcentric , Founder",
      image: Qualcentric,
      Description:
        "What was different with them is that they took the time, initiative and market understanding to seamlessly over come various obstacles we thought we had. Thank you guys!",
    },
    {
      name: "Thiagarajan",
      position: "Tripeur , Founder",
      image: Tripeur,
      Description:
        "The team at eternalight, helped us fine tune our vision and bring it to life, It was in budget and on time. We would definitely recommend them !",
    },
    {
      name: "Gautham",
      position: "Lumen Stocks , Founder",
      image: Lumen,
      Description:
        "We worked with eternalight remotely and accomplished our goals like we were working for the same objectives as a single unit sitting from the same office. Our experience with them has been fantastic.",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "50px",
        padding: "5%",
        cursor: "pointer",
      }}
      id="feedback"
    >
      <div>
        <div
          style={{ fontSize: "38px", fontWeight: "600", textAlign: "center" }}
        >
          Feedback from
        </div>
        <div
          style={{
            fontSize: "38px",
            fontWeight: "600",
            color: "#0078D1",
            textAlign: "center",
          }}
        >
          Our Client
        </div>
      </div>
      <div className={styles.feedbackCardParent}>
        {clientFeedback.map((res, index) => (
          <div className={styles.feedBackCard}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#171717",
                }}
              >
                Eternalight
              </span>
              <div
                style={{
                  width: "40px",
                  height: "3px",
                  background: "#085996",
                }}
              ></div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <img
                style={{
                  width: "60px",
                  border: "1px solid #0078D1",
                  padding: "1px",
                  borderRadius: "50%",
                }}
                src={res.image}
                alt="profile"
              />
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#171717",
                }}
              >
                {res.name}
              </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#171717",
                }}
              >
                {res.position}
              </span>
            </div>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#596080",
              }}
            >
              {res.Description}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeedBack;
