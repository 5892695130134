import React from 'react'
import { AiOutlineHome } from "react-icons/ai";
import "./caseStudy.css";
import {
  WordPressIcon,
  FigmaIcon,
  CloudFrontIcon,
  Comp1,
  CSAbout,
  CSSolution,
  CSImpact,
  CSBanner,
  Arrow,
  NodeJs,
  AngularJs,
  ReactJs,
  XAbout,
  XImpact,
  XProblem,
  XSolution,
  TAbout,
  TProblem,
  TSolution,
  TImpact,
} from "../../Assets";
import ContactForm from "../Home/ContactForm";
import { div } from "@tensorflow/tfjs";
const TripeurGSTReconcileCaseStudy = () => {
  const problemList = [
    "In 2017, the introduction of the Goods and Services Tax (GST) marked a groundbreaking shift in India's tax landscape, aiming to simplify taxation with the principle of 'one nation, one tax.' Intriguingly, each year, companies lose hundreds of crores in GST credit due to the lack of proper reconciliation among multiple parties.",
    "Amid the challenges presented by the COVID-19 pandemic, Tripeur recognized the need to create a dedicated product that could facilitate GST tax reconciliation for travel-related invoices among various stakeholders, including companies, airlines, hotels, railways, and bus vendors.",
    "The complexity of the task was exacerbated by the fact that these invoices arrive in various formats. On average, companies receive a staggering 150,000 invoices annually. Manually reconciling taxes for each invoice would have been a logistical nightmare for finance departments, leading many larger companies to forego claiming their GST input credits.",
  ];
  const solutionList = [
    "To streamline the onboarding process, we set up separate email addresses for each Tripeur customer and integrated them with Freshdesk. This setup ensured that whenever a vendor's email landed in Freshdesk, our system seamlessly ingested the invoices, securely storing them in an S3 bucket.",
    "To facilitate data understanding, we designed a PDF/Image/text parser service. This service parsed invoice data and transformed it into a user-friendly format.",
    "In addition, we developed a service capable of identifying missing invoices and retrieving them from the vendor portal or sending periodic email reminders.",
    "A pivotal component of our solution was the reconciliation system. This system compared data and provided detailed insights, highlighting missing invoices, tax amount discrepancies, and unclaimed credit amounts, offering a comprehensive overview of the financial landscape.",
  ];
  const impactList = [
    "Thanks to a successful GST reconciliation, the product launch resulted in a 6% expansion of their customer base.",
    "The GST reconciliation product enabled companies to successfully claim approximately 1 million dollars in credit.",
    "Product helped companies to find approximately 10K missing invoices.",
  ];
  return (
    <div>
      {" "}
      <div className="themeColor1 caseDetails">
        <div
          style={{
            color: "#596080",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1px",
            marginTop: "20px",
          }}
        >
          <a href="/">
            <AiOutlineHome />
          </a>
          <span>
            <a style={{ textDecoration: 'none' }} href="/case-study">/ Case Study</a>/ Tripeur GST Reconcile{" "}
          </span>{" "}
        </div>
        <img src={Comp1} style={{ height: "100px" }} alt="Company pic" />
        <span className="heading caseDetailsTittle">Your Ultimate GST Reconciliation Companion</span>
        <div style={{ fontSize: "20px", fontWeight: "400", color: "#171717" }}>
          Technology Used
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "30px",
            border: "1px solid var(--blue-10-opacity, rgba(6, 102, 174, 0.10))",
            padding: "10px",
            borderRadius: "20px",
            width: "250px",
            justifyContent: "space-around"
          }}
        >
          {[NodeJs,
            AngularJs,
            "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",].map((res, index) => (
              <img src={res} alt="icons" className="techImage" />
            ))}
        </div>
      </div>
      <div className="caseDetailsFirstSection">
        <div className="caseDetailsFirstSectionBody">
          <span
            style={{
              fontSize: "38px",
              fontWeight: "700",
            }}
          >
            About
          </span>
          <img
            src={TAbout}
            alt="TAbout"
            className="caseDetailsFirstSectionImage mobileHide"
          />
          <span className="caseDetailsFirstSectionText">
            Tripeur is an end-to-end corporate travel management platform that uses
            advanced Artificial Intelligence and Machine Learning algorithms to drive
            efficiency, ease of use, personalization and transparency for business
            travel by employees and executives alike.
          </span>
        </div>

        <img
          src={TAbout}
          alt="TAbout"
          className="caseDetailsFirstSectionImage webHide"
        />
      </div>

      <div className="caseDetailsBannerBody" style={{ margin: "10px 0" }}>
        <img
          className="caseDetailsBannerImage"
          src={TProblem}
          alt="TProblem"
        />
      </div>

      <div className="caseDetailsProblemBody">
        <span style={{ fontSize: "38px", fontWeight: "700" }}>Problem</span>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {problemList.map((res, index) => (
            <div className="caseDetailsProblemArray">
              <img src={Arrow} alt="Arrow" style={{ width: "30px" }} />
              <span>{res}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="caseDetailsSolutionBody">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "90%", height: "90%", borderRadius: "10px" }}
            src={TSolution}
            alt="TSolution"
          />
        </div>
        <div className="caseDetailsTextBody">
          <span style={{ fontSize: "38px", fontWeight: "700" }}>solution</span>
          <span style={{ fontSize: "18px", fontWeight: "400" }}>
            Tripeur embarked on a project to meet a crucial requirement:
            the development of a product that could automate the entire process
            from start to finish, eliminating the need for manual intervention.
            The system's primary functions included invoice ingestion,
            data parsing, data comparison with travel vendor invoices,
            and the generation of comprehensive reconciliation reports.
          </span>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
            {solutionList.map((res, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <img src={Arrow} alt="Arrow" />
                <span>{res}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="caseDetailsImpactBody">
        <div className="caseDetailsTextBody">
          <span style={{ fontSize: "38px", fontWeight: "700" }}>impact</span>
          <img
            style={{ width: "90%", borderRadius: "10px" }}
            src={TImpact}
            alt="TImapct"
            className="mobileHide"
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {impactList.map((res, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <img src={Arrow} alt="Arrow" />
                <span>{res}</span>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            style={{ width: "540px", height: "540px", borderRadius: "10px" }}
            src={TImpact}
            alt="TImapct"
            className="webHide"
          ></img>
        </div>
      </div>

      <ContactForm />
    </div >
  );
};
export default TripeurGSTReconcileCaseStudy