import React from 'react'
import { AiOutlineHome } from "react-icons/ai";
import "./caseStudy.css";
import {
  WordPressIcon,
  FigmaIcon,
  CloudFrontIcon,
  Comp1,
  CSAbout,
  CSSolution,
  CSImpact,
  CSBanner,
  Arrow,
  NodeJs,
  AngularJs,
  ReactJs,
  XAbout,
  XImpact,
  XProblem,
  XSolution,
} from "../../Assets";
import ContactForm from "../Home/ContactForm";
import { div } from "@tensorflow/tfjs";
const XetlinkCaseStudy = () => {
  const problemList = [
    "As a fascinating fact, approximately 55 million meetings occur worldwide every day. Many of these meetings involve participants from various locations. While English serves as a common global language, it's interesting to note that 75% of the world's population doesn't speak English. To address this challenge, Xetlink embarked on the mission to develop a product capable of supporting meetings in multiple languages in real-time.",
    "A pivotal aspect of this solution was the need to capture the voice of the speaker in each meeting. To achieve this, Xetlink aimed to create a bot that could autonomously join meetings, record conversations, and then pass the recorded content to a converter for processing.",
    "Another crucial challenge was the post-recording phase. Once the voice was recorded and converted into text, the bot needed to be equipped with the capability to translate the text into the user's preferred language, making it a comprehensive language-agnostic solution for seamless global communication.",
  ];
  const solutionList = [
    "As part of their solution, Xetlink sought the development of a bot capable of participating in meetings, capturing the active speaker's voice, transcribing chat conversations, and forwarding this information to a translation service.",
    "To achieve this, we implemented automation bots for popular platforms like Google Meet, Webex, and Microsoft Teams. These bots utilized Puppeteer to launch browsers and join meetings, mimicking real participants. Once the bot identified the active speaker, it streamed the audio to a server.",
    "We also crafted Puppeteer scripts to capture active users, chat text, and monitor the activity of departing meeting participants, transmitting all this data via a websocket.",
    "A websocket was created to continuously stream audio events, ensuring that users at the other end received live, translated text in their chosen language.",
    "Furthermore, we designed a bot to simulate user voices using the FFmpeg library, allowing for voice conversion between different languages.",
    "To maintain bot anonymity and bypass security measures, we implemented Puppeteer security bypass methodologies to avoid detection during Google Meet and Microsoft Teams calls.",
  ];
  const impactList = [
    "Xetlink successfully diversified its service offering across multiple meeting providers, breaking free from their previous restriction to Zoom alone. This strategic expansion had a significant impact, contributing to an 8% increase in their overall revenue.",
    "Furthermore, the introduction of premium features, such as the ability to simulate bot voices, provided Xetlink with a notable competitive advantage over their industry rivals, further solidifying their position in the mark.",
    "Previously, Xetlink relied on Windows-based automation for meeting participation. However, with the transition to a Linux-based solution, they achieved a substantial 22% reduction in operational costs. Subsequently, they leveraged a Docker-based system to efficiently scale their traffic, enhancing their ability to handle increased demand and further optimize their operations.",
  ];
  return (
    <div>
      {" "}
      <div className="themeColor1 caseDetails">
        <div
          style={{
            color: "#596080",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1px",
            marginTop: "20px",
          }}
        >
          <a href="/">
            <AiOutlineHome />
          </a>
          <span>
            <a style={{ textDecoration: 'none' }} href="/case-study">/ Case Study</a>/ Xetlink{" "}
          </span>{" "}
        </div>
        <img src={"https://xetlink.ai/wp-content/uploads/2022/11/Xetlink_Logofiles_Full-Logo-3.png.webp"} style={{ height: "100px" }} alt="Company pic" />
        <span className="heading caseDetailsTittle">Xetlink Voice-To-Language Conversion</span>
        <div style={{ fontSize: "20px", fontWeight: "400", color: "#171717" }}>
          Technology Used
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "30px",
            border: "1px solid var(--blue-10-opacity, rgba(6, 102, 174, 0.10))",
            padding: "10px",
            borderRadius: "20px",
            width: "250px",
            justifyContent: "space-around"
          }}
        >
          {[NodeJs,
            "https://taglineinfotech.com/wp-content/uploads/2022/08/puppet.svg",
            "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",].map((res, index) => (
              <img src={res} alt="icons" className="techImage" />
            ))}
        </div>
      </div>
      <div className="caseDetailsFirstSection">
        <div className="caseDetailsFirstSectionBody">
          <span
            style={{
              fontSize: "38px",
              fontWeight: "700",
            }}
          >
            About
          </span>
          <img
            src={XAbout}
            alt="XAbout"
            className="caseDetailsFirstSectionImage mobileHide"
          />
          <span
            style={{
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "28px",
              width: "70%",
            }}
          >
            Xetlink Technology Inc has built Xetlink AI, a real-time multilingual
            communication solution for video conferencing. The technology provides
            speech-to-text and speech-to-speech translation, meeting transcript analysis,
            and transcription capabilities, allowing individuals and organizations to conduct
            meetings with people who speak different languages without the need for a translator.
          </span>
        </div>
        <div>
          <img
            src={XAbout}
            alt="XAbout"
            className="caseDetailsFirstSectionImage webHide"
          />
        </div>
      </div>
      <div className="caseDetailsBannerBody" style={{ margin: "10px 0"}}>
        <img
          className="caseDetailsBannerImage"
          src={XProblem}
          alt="XProblem"
        />
      </div>
       <div className="caseDetailsProblemBody">
        <span style={{ fontSize: "38px", fontWeight: "700" }}>Problem</span>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {problemList.map((res, index) => (
             <div className="caseDetailsProblemArray">
              <img src={Arrow} alt="Arrow" style={{ width: "30px" }} />
              <span>{res}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="caseDetailsSolutionBody">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "90%", height: "90%", borderRadius: "10px" }}
            src={XSolution}
            alt="XSolution"
          />
        </div>
         <div className="caseDetailsTextBody">
          <span style={{ fontSize: "38px", fontWeight: "700" }}>solution</span>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
            {solutionList.map((res, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <img src={Arrow} alt="Arrow" />
                <span>{res}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="caseDetailsImpactBody">
        <div className="caseDetailsTextBody">
          <span style={{ fontSize: "38px", fontWeight: "700" }}>impact</span>
          <img
            style={{width: "90%", borderRadius: "10px" }}
            src={XImpact}
            alt="XImapct"
            className="mobileHide"
          ></img>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {impactList.map((res, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <img src={Arrow} alt="Arrow" />
                <span>{res}</span>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            style={{ width: "540px", height: "540px", borderRadius: "10px" }}
            src={XImpact}
            alt="XImapct"
            className="webHide"
          ></img>
        </div>
      </div>

      <ContactForm />
    </div>
  );
};
export default XetlinkCaseStudy