import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import "./caseStudy.css";
import {
  WordPressIcon,
  FigmaIcon,
  CloudFrontIcon,
  Comp1,
  CSAbout,
  CSSolution,
  CSImpact,
  CSBanner,
  Arrow,
  NodeJs,
  AngularJs,
  ReactJs,
} from "../../Assets";
import ContactForm from "../Home/ContactForm";

const TripeurCaseStudy = () => {
  const problemList = [
    "Sluggish response times from aggregator flight search results.",
    "Absence of new airline options in aggregator listings.",
    "An error rate of approximately 3-10% in the flight booking flow.",
    "Inadequate error response handling from aggregators.",
    "Limited availability of discounts and offers from aggregators.",
  ];
  const solutionList = [
    "Established direct connectivity with Low-Cost Carrier (LCC) airlines.",
    "Integrated seamlessly with the discount and offer systems of LCC airlines.",
    "Deployed a scalable solution using Kubernetes and a serverless architecture.",
    "Implemented result caching for frequently searched flights.",
    "Additionally, developed a proof of concept (POC) for flight price prediction to assist users in selecting the most cost-effective flight options.",
  ];
  const impactList = [
    "Achieved comprehensive coverage, encompassing nearly 99% of airline listings.",
    "Significantly improved the search-to-booking ratio, soaring from 40% to 60%.",
    "Experienced a remarkable increase in Customer Satisfaction (CSAT) scores, surging from 32% to 62%.",
    "Successfully onboarded three new clients within just two months, offering a range of discounts and airlines to choose from.",
  ];

  return (
    <div>
      {" "}
      <div className="themeColor1 caseDetails">
        <div
          style={{
            color: "#596080",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1px",
            marginTop: "20px",
          }}
        >
          <a href="/">
            <AiOutlineHome />
          </a>
          <span>
            <a style={{ textDecoration: "none" }} href="/case-study">
              / Case Study
            </a>
            / Tripeur{" "}
          </span>{" "}
        </div>
        <img src={Comp1} style={{ height: "100px" }} alt="Company pic" />
        <span className="heading caseDetailsTittle">
        How Tripeur Optimized Booking Process
        </span>
        <div style={{ fontSize: "20px", fontWeight: "400", color: "#171717" }}>
          Technology Used
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "30px",
            border: "1px solid var(--blue-10-opacity, rgba(6, 102, 174, 0.10))",
            padding: "10px",
            borderRadius: "20px",
            width: "250px",
            justifyContent: "space-around",
          }}
        >
          {[NodeJs, AngularJs, ReactJs].map((res, index) => (
            <img src={res} alt="icons" className="techImage" />
          ))}
        </div>
      </div>
      <div className="caseDetailsFirstSection">
        <div className="caseDetailsFirstSectionBody">
          <span
            style={{
              fontSize: "38px",
              fontWeight: "700",
            }}
          >
            About
          </span>
          <img
            src={CSAbout}
            alt="CSAbout"
            className="caseDetailsFirstSectionImage mobileHide"
          />
          <span className="caseDetailsFirstSectionText">
            Tripeur is an end-to-end corporate travel management platform that
            uses advanced Artificial Intelligence and Machine Learning
            algorithms to drive efficiency, ease of use, personalization and
            transparency for business travel by employees and executives alike.
          </span>
        </div>
        <img
          src={CSAbout}
          alt="CSAbout"
          className="caseDetailsFirstSectionImage webHide"
        />
      </div>
      <div className="caseDetailsBannerBody" style={{ margin: "10px 0" }}>
        <img
          className="caseDetailsBannerImage"
          src={CSSolution}
          alt="CSSolution"
        />
      </div>
      <div className="caseDetailsProblemBody">
        <span style={{ fontSize: "38px", fontWeight: "700" }}>Problem</span>
        <span style={{ fontSize: "1rem", fontWeight: "400" }}>
          Tripeur relies on a range of GDS and Aggregators to source flight
          content for customer display, but their platform faced significant
          technical challenges that hindered further scalability.
        </span>
        {/* </div> */}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {problemList.map((res, index) => (
            <div className="caseDetailsProblemArray">
              <img src={Arrow} alt="Arrow" />
              <span>{res}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="caseDetailsSolutionBody">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "90%", height: "90%", borderRadius: "10px" }}
            src={CSBanner}
            alt="CSBanner"
          />
        </div>
        <div className="caseDetailsTextBody">
          <span style={{ fontSize: "38px", fontWeight: "700" }}>solution</span>
          <span style={{ fontSize: "18px", fontWeight: "400" }}>
            As part of the solution they were looking for technology partners
            who can have the experience working with the Travel industry and
            build a solid platform that serves millions of customers.
          </span>
          <span style={{ fontSize: "18px", fontWeight: "400", gap: "15px" }}>
            As part of the discussion we implemented the solutions below.
          </span>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
            {solutionList.map((res, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <img src={Arrow} alt="Arrow" />
                <span>{res}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="caseDetailsImpactBody">
        <div className="caseDetailsTextBody">
          <span style={{ fontSize: "38px", fontWeight: "700" }}>impact</span>
          <img
            style={{ width: "90%", borderRadius: "10px" }}
            src={CSImpact}
            alt="CSImapct"
            className="mobileHide"
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {impactList.map((res, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <img src={Arrow} alt="Arrow" />
                <span>{res}</span>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            style={{ width: "540px", height: "540px", borderRadius: "10px" }}
            src={CSImpact}
            alt="CSImapct"
            className="webHide"
          />
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default TripeurCaseStudy;
