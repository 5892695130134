import React from "react";
import Styles from "../Home/home.module.css";
import { ContactImage } from "../../Assets";
import "./utilsCss.css";

const DiscussForm = () => {
  return (
    <div className="themeColor1 contactFormMain">
      <div className="contactFormParent">
        <div
          style={{ display: "flex", flexDirection: "column", gap: "50px" }}
          className="contactFormInputBox"
        >
          <sapn style={{ fontSize: "35px", fontWeight: "700" }}>
            Ready to <span style={{ color: "#0078D1" }}> Discuss Project</span>
          </sapn>
          <div
            style={{
              border:
                "1px solid var(--blue-20-opacity, rgba(6, 102, 174, 0.20))",
              borderRadius: "24px",
              padding: "20px",
            }}
            className={`${Styles.blogCardParent} contactFormImagebox mobileHide`}
          >
            <img
              src={ContactImage}
              alt="ContactImage"
              style={{ borderRadius: "10px", width: "10s0%" }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <div
              className={Styles.inputFormParent}
              style={{ display: "flex", gap: "10px" }}
            >
              <input
                type="text"
                placeholder="Name"
                className={Styles.inputForm}
                style={{
                  borderBottom: "1px solid #1717173d",
                  borderRadius: "0",
                }}
              />
              <input
                type="text"
                placeholder="Email address"
                className={Styles.inputForm}
                style={{
                  borderBottom: "1px solid #1717173d",
                  borderRadius: "0",
                }}
              />
            </div>
            <input
              type="text"
              placeholder="Your message"
              className={Styles.inputForm}
              style={{
                width: "90%",
                borderBottom: "1px solid #1717173d",
                borderRadius: "0",
              }}
            />
          </div>
          <button
            style={{
              border: "1px solid rgba(23, 23, 23, 0.50)",
              borderRadius: "10px",
              width: "200px",
              padding: "15px",
              cursor: "pointer",
              background: "#fff",
            }}
          >
            Let's Work Together
          </button>
        </div>
        <div
          style={{
            border: "1px solid var(--blue-20-opacity, rgba(6, 102, 174, 0.20))",
            borderRadius: "24px",
            padding: "10px",
          }}
          className="contactFormImagebox webHide"
        >
          <img
            src={ContactImage}
            alt="ContactImage"
            style={{ borderRadius: "10px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default DiscussForm;
