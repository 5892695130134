import React, { useEffect, useRef } from "react";
import Styles from "./home.module.css";
import {
  WordPressIcon,
  FigmaIcon,
  CloudFrontIcon,
  GST,
  KinderPest,
  TripeurImage,
} from "../../Assets";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ExpMngVideo from "../../Assets/video/expenseManagment.webm";
import Xetlink from "../../Assets/video/Xetlink.webm";
import Spotsaas from "../../Assets/video/Spotsaas.webm";
import { NodeJs, ReactJs, AngularJs } from "../../Assets";
const CaseStudy = () => {
  const caseStudyArray = [
    {
      tittle: "Xetlink",
      description:
        "Xetlink Technology Inc has built Xetlink AI, a real-time multilingual communication solution for video conferencing. The technology provides speech-to-text and speech-to-speech translation, meeting transcript analysis, and transcription capabilities, allowing individuals and organizations to conduct meetings with people who speak different languages without the need for a translator.",
      technologies: [
        {
          img: NodeJs,
        },
        {
          img: "https://taglineinfotech.com/wp-content/uploads/2022/08/puppet.svg",
        },
        {
          img: "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",
        },
      ],
      file: Xetlink,
      type: "video",
    },
    {
      tittle: "Spotsaas",
      description:
        "SpotSaaS is a software recommendation platform where we connect buyers and sellers of software products. We help the software buyers make a better product decision based on unbiased reviews, software comparisons, social popularity, and buyers guide.",
      technologies: [
        {
          img: NodeJs,
        },
        {
          img: "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",
        },
        {
          img: ReactJs,
        },
      ],
      file: Spotsaas,
      type: "video",
    },
    {
      tittle: "Tripeur",
      description:
        "Tripeur is an end-to-end corporate travel management platform that uses advanced Artificial Intelligence and Machine Learning algorithms to drive efficiency, ease of use, personalization and transparency for business travel by employees and executives alike.",
      technologies: [
        {
          img: NodeJs,
        },
        {
          img: AngularJs,
        },
        {
          img: ReactJs,
        },
        {
          img: "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",
        },
      ],
      file: TripeurImage,
      type: "image",
    },
  ];

  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  });
  return (
    <div
      style={{
        padding: "30px",
      }}
      id="work"
    >
      <Carousel
        // autoPlay
        infiniteLoop
        showArrows={false}
        showStatus={false}
        showThumbs={false}
      >
        {caseStudyArray.map((res, index) => (
          <div className={Styles.caseStudyBody} key={index}>
            <div>
              {res.type === "image" && (
                <img
                  className={Styles.caseStudyImage}
                  src={res.file}
                  alt="CaseStudyImage"
                />
              )}
              {res.type === "video" && (
                <video
                  className={`${Styles.aboutVideo} ${Styles.caseStudyImage}`}
                  ref={videoRef}
                  loop
                  autoPlay
                  muted
                  playsInline
                >
                  <source src={res.file} type="video/mp4" />
                </video>
              )}
            </div>
            <div
              className={Styles.caseStudyContent}
              style={{ justifyContent: "center" }}
            >
              <span
                style={{
                  margin: "20px 0",
                  fontSize: "38px",
                  fontWeight: "600",
                }}
              >
                {res.tittle}{" "}
                <span style={{ color: "#0078D1", fontWeight: "700" }}>
                  Case Study
                </span>
              </span>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#596080",
                  textAlign: "start",
                }}
              >
                {res.description}
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#171717",
                  }}
                >
                  Technology
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "30px",
                    alignItems: "center",
                  }}
                >
                  {res.technologies.map((res, index) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <img
                        style={{
                          width: "40px",
                          height: "50px",
                        }}
                        src={res?.img}
                        alt="icons"
                      />
                    </div>
                  ))}
                </div>
              </div>

              <button
                onClick={() => {
                  window.location.href = `/case-study/${res.tittle}`;
                }}
                className={Styles.themeBtn}
              >
                View Case Study
              </button>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CaseStudy;
