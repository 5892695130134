import React from "react";
import {
  FBIcon,
  InstaIcon,
  TwitIcon,
  LinkedInIcon,
  WikiIcon,
  Logo,
} from "../../Assets";
import styles from "./footer.module.css";
const Footer = () => {

  const handleClick = () => {};
  const socialIcons = [LinkedInIcon];

  const scrollToSection = async (sectionId) => {
    localStorage.setItem("currentSection", sectionId);
    if (sectionId === "about") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#about";
        return;
      }
      const offsetTop = 700;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "ourStory") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#ourStory";
        return;
      }
      const offsetTop = 4000;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "feedback") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#feedback";
        return;
      }
      const offsetTop = 2500;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "work") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#work";
        return;
      }
      const offsetTop = 5500;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "contact-form") {
      const offsetTop = window.location.href.includes("case-study")
        ? 4000
        : 6200;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "ourServices") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#ourServices";
        return;
      }
      const offsetTop = 2300;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  return (
    <div className={styles.footerBody}>
      <div className={styles.socials}>
        <span style={{ margin: "20px 0", fontSize: "38px", fontWeight: "600" }}>
          Let’s work{" "}
          <span style={{ color: "#0078D1", fontWeight: "700" }}>together</span>
        </span>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {socialIcons.map((res) => (
            <a
              href=" https://in.linkedin.com/company/eternalight-infotech"
              onClick={handleClick}
              target="blank"
            >
              <img src={res} alt="SocialIcon" />
            </a>
          ))}
        </div>
      </div>
      <div style={{ marginTop: 0 }} className={styles.divider}></div>
      <div className={styles.boxStyle}>
        <div>
          <img
            style={{ width: "100%", marginTop: "5px" }}
            src={Logo}
            alt="Company Logo"
          />
          <span>
            Unlocking Digital Brilliance | Eternalight - Your Partner in
            Creative Solutions
          </span>
        </div>
        <div>
          <h3>Quick Links</h3>
          <span onClick={()=>scrollToSection("feedback")}>
            <a
              href="/#feedback"
              style={{ color: "#596080", textDecoration: "none" }}
            >
              {" "}
              Testimonials{" "}
            </a>
          </span>
          <span onClick={()=>scrollToSection("work")}>
            {" "}
            <a
              href={
                window.location.href === "http://localhost:3000"
                  ? "/#work"
                  : "/case-study"
              }
              style={{ color: "#596080", textDecoration: "none" }}
            >
              {" "}
              Case Study{" "}
            </a>
          </span>
          <span onClick={()=>scrollToSection("about")}>
            <a
              href="/#about"
              style={{ color: "#596080", textDecoration: "none" }}
            >
              {" "}
              How we Different{" "}
            </a>
          </span>
        </div>
        <div>
          <h3>How we Different</h3>
          <span onClick={()=>scrollToSection("ourStory")}>
            <a
              href="/#ourStory"
              style={{ color: "#596080", textDecoration: "none" }}
            >
              {" "}
              About{" "}
            </a>
          </span>
          <span onClick={()=>scrollToSection("contact-form")}>
            {" "}
            <a
              href="#contact-form"
              style={{ color: "#596080", textDecoration: "none" }}
            >
              {" "}
              Contact{" "}
            </a>
          </span>
          {/* <span>Blogs</span> */}
        </div>
        <div onClick={()=>scrollToSection("ourServices")}>
          <h3>Services</h3>
          <span>
            {" "}
            <a
              href="/#ourServices"
              style={{ color: "#596080", textDecoration: "none" }}
            >
              {" "}
              Web Development{" "}
            </a>
          </span>
          <span>
            {" "}
            <a
              href="/#ourServices"
              style={{ color: "#596080", textDecoration: "none" }}
            >
              {" "}
              Mobile App Development{" "}
            </a>
          </span>
          <span>
            {" "}
            <a
              href="/#ourServices"
              style={{ color: "#596080", textDecoration: "none" }}
            >
              {" "}
              Backend Development{" "}
            </a>
          </span>
          <span>
            {" "}
            <a
              href="/#ourServices"
              style={{ color: "#596080", textDecoration: "none" }}
            >
              {" "}
              Cloud Deployment{" "}
            </a>
          </span>
          <span>
            {" "}
            <a
              href="/#ourServices"
              style={{ color: "#596080", textDecoration: "none" }}
            >
              {" "}
              UI/UX Design{" "}
            </a>
          </span>
        </div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.copyRight}>
        <span>Copyright ©2023 by Eternalight</span>
        <span>Privacy Policy Terms & Conditions</span>
      </div>
    </div>
  );
};

export default Footer;
