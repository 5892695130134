import React from 'react'
import HomePage from '../components/Home'
import Navbar from '../components/NavBar/navbar'
import Footer from '../components/Footer'
import {
    Route, Routes,
} from "react-router-dom";
import BlogPage from '../components/Blog';
import CaseStudy from '../components/caseStudy';
import TripeurCaseStudy from '../components/caseStudy/TripeurCaseStudy';
import XetlinkCaseStudy from '../components/caseStudy/XetlinkCaseStudy';
import SpotsaasCaseStudy from '../components/caseStudy/SpotsaasCaseStudy';
import TripeurGSTReconcileCaseStudy from '../components/caseStudy/TripeurGSTReconcileCaseStudy';
import TripeurExpenseManagementCaseStudy from '../components/caseStudy/TripeurExpenseManagementCaseStudy';
const Pages = () => {
    return (
        <div>
            <Navbar />
            <Routes>
                <Route path="/" element={<HomePage />}></Route>
                <Route path="/blog" element={<BlogPage />}></Route>
                <Route path="/case-study" element={<CaseStudy />}></Route>
                <Route path="/case-study/Tripeur" element={<TripeurCaseStudy />}></Route>
                <Route path="/case-study/Xetlink" element={<XetlinkCaseStudy />}></Route>
                <Route path="/case-study/Spotsaas" element={<SpotsaasCaseStudy />}></Route>
                <Route path="/case-study/Tripeur GST Reconcile" element={<TripeurGSTReconcileCaseStudy />}></Route>
                <Route path="/case-study/Tripeur Expense Management" element={<TripeurExpenseManagementCaseStudy />}></Route>
            </Routes>
            <Footer />
        </div>
    )
}

export default Pages