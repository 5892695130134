import React, { useState } from 'react';
import { AiOutlineHome } from "react-icons/ai";
import {
    CS1,
    CS2,
    CS3,
    CS4,
    WordPressIcon,
    FigmaIcon,
    CloudFrontIcon,
    GST,
    KinderPest,
    TripeurImage,
    NodeJs,
    ReactJs,
    AngularJs,
} from '../../Assets'
import CaseStudyCard from './caseStudyCard';
import DiscussForm from '../Utils/DiscussForm';
import ExpMngVideo from "../../Assets/video/expenseManagment.webm";
import Xetlink from "../../Assets/video/Xetlink.webm";
import Spotsaas from "../../Assets/video/Spotsaas.webm";
import ContactForm from '../Home/ContactForm';
import "./caseStudy.css"

import TripeurCaseStudy from './TripeurCaseStudy';
const CaseStudy = () => {

    const caseStudyData = [
        {
            tittle: "Tripeur",
            description: "Tripeur is an end-to-end corporate travel management platform that uses advanced Artificial Intelligence and Machine Learning algorithms to drive efficiency, ease of use, personalization and transparency for business travel by employees and executives alike.",
            section: ['Mobile', 'Front-end', 'Back-end'],
            image: TripeurImage,
            type: "image",
            technologies: [
                NodeJs,
                AngularJs,
                ReactJs,
            ],
        },
        {
            tittle: "Tripeur GST Reconcile",
            description: "Tripeur is an end-to-end corporate travel management platform that uses advanced Artificial Intelligence and Machine Learning algorithms to drive efficiency, ease of use, personalization and transparency for business travel by employees and executives alike.",
            section: ['Mobile', 'Front-end', 'Back-end'],
            image: GST,
            type: "image",
            technologies: [
                NodeJs,
                AngularJs,
                "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",],
        },
        {
            tittle: "Tripeur Expense Management",
            description: "Tripeur is an end-to-end corporate travel management platform that uses advanced Artificial Intelligence and Machine Learning algorithms to drive efficiency, ease of use, personalization and transparency for business travel by employees and executives alike.",
            section: ['Mobile', 'Front-end', 'Back-end'],
            image: ExpMngVideo,
            type: "video",
            technologies: [
                NodeJs,
                ReactJs,
                "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",],
        },
        {
            tittle: "Kindred Pest Control",
            description: "Kindred operates as a residential pest control company, offering a range of services including pest control, bed bug treatments, indoor flea treatments, and German roach treatments. Their service model encompasses both one-time service requests and subscription-based plans.",
            section: ['Mobile', 'Front-end', 'Back-end'],
            image: KinderPest,
            type: "image",
            technologies: [WordPressIcon, FigmaIcon, CloudFrontIcon],
        },
        {
            tittle: "Xetlink",
            description: "Xetlink Technology Inc has built Xetlink AI, a real-time multilingual communication solution for video conferencing. The technology provides speech-to-text and speech-to-speech translation, meeting transcript analysis, and transcription capabilities, allowing individuals and organizations to conduct meetings with people who speak different languages without the need for a translator.",
            section: ['Mobile', 'Front-end', 'Back-end'],
            image: Xetlink,
            type: "video",
            technologies: [
                NodeJs,
                "https://taglineinfotech.com/wp-content/uploads/2022/08/puppet.svg",
                "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",],
        },
        {
            tittle: "Spotsaas",
            description: "SpotSaaS is a software recommendation platform where we connect buyers and sellers of software products. We help the software buyers make a better product decision based on unbiased reviews, software comparisons, social popularity, and buyers guide.",
            section: ['Mobile', 'Front-end', 'Back-end'],
            image: Spotsaas,
            type: "video",
            technologies: [
                NodeJs,
                "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",
                ReactJs,
            ],
        },
    ]
    // const topSectionData = ['All', 'Back-end', 'Design', 'FinTech', 'Front-end', 'Healthcare', ' iGaming', ' Maintenance', 'Mobile', 'Web']
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div className="themeColor1 topbar">
                <div style={{ color: "#596080", display: "flex", flexDirection: "row", alignItems: "center", gap: "1px" }}>
                    <a style={{ textDecoration: 'none' }} href='/'>
                        <AiOutlineHome />
                    </a>
                    <span> / Our Case Study </span> </div>
                <span className="heading">Our Case Study</span>
            </div>
            <div className='cardBody'>
                {caseStudyData.map((res) => (
                    <CaseStudyCard caseStudyData={res} />
                ))}
            </div>
            <ContactForm />


        </div>
    )
}

export default CaseStudy