import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import {
  CSImpact,
  CSBanner,
  Arrow,
  SpotSaaS,
  NodeJs,
  ReactJs,
  SAbout,
  SImpact,
  SBanner,
  SSolution,
  AngularJs,
} from "../../Assets";
import ContactForm from "../Home/ContactForm";
const SpotsaasCaseStudy = () => {
  const problemList = [
    "On average, each company relies on more than 20 SaaS (Software as a Service) applications to facilitate their day-to-day operations, spanning from recruitment to sales.",
    "Selecting the perfect software for a specific use case can be quite challenging when faced with a multitude of over a thousand options. Sometimes, the ideal software solutions are available, but due to insufficient research, companies may inadvertently choose poorly suited software.",
    "While numerous videos and blogs provide insights on software and product selection, maintaining up-to-date content can be a significant challenge. Additionally, influencers often exhibit bias towards their preferred software solutions.",
    "SpotSass initially developed the MVP (Minimum Viable Product) in-house, but encountered performance issues, primarily due to handling extensive data. They sought to enhance the user experience by improving API response times.",
  ];
  const solutionList = [
    "SpotSaaS recognized the numerous challenges associated with software procurement and took the initiative to develop a comprehensive tool. This tool allows companies to efficiently search for software solutions and their respective features. Users can also compare features and pricing among multiple vendors, empowering them to make informed and precise decisions.",
    "To optimize API response times, We implemented a robust structural caching system utilizing Redis and MongoDB. We rearchitected multiple APIs to effectively store caching data and introduced burst strategies to enhance performance.",
    "In addition, the team undertook a significant refactoring effort, addressing more than 40 APIs and their input parameters. They introduced pagination and additional filters to provide rich functionality on the frontend, improving the overall user experience.",
    "Furthermore, We made significant enhancements by streamlining legacy queries. This involved eliminating multiple cross-joins on tables and implementing multiple indexes on the MongoDB database, resulting in improved database performance.",
  ];
  const impactList = [
    "By reducing API response times from approximately 6 seconds to a speedy 400 milliseconds, the user engagement and active time on the website significantly increased.",
    "Furthermore, after the comprehensive API refactoring efforts, the websites page load time was dramatically reduced from about 9 seconds to a swift 1.5 seconds, further enhancing the overall user experience.",
    "Additionally, these improvements led to a commendable 12% reduction in database costs, alongside notable enhancements in the efficiency of database queries.",
  ];
  return (
    <div>
      {" "}
      <div className="themeColor1 caseDetails">
        <div
          style={{
            color: "#596080",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1px",
            marginTop: "20px",
          }}
        >
          <a href="/">
            <AiOutlineHome />
          </a>
          <span>
            <a style={{ textDecoration: "none" }} href="/case-study">
              / Case Study
            </a>
            / Spotsaas{" "}
          </span>{" "}
        </div>
        <img src={SpotSaaS} alt="Company pic" />
        <span className="heading caseDetailsTittle">
        SaaSVue Unified Visibility Across Platforms
        </span>
        <div style={{ fontSize: "20px", fontWeight: "400", color: "#171717" }}>
          Technology Used
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "30px",
            border: "1px solid var(--blue-10-opacity, rgba(6, 102, 174, 0.10))",
            padding: "10px",
            borderRadius: "20px",
            width: "250px",
            justifyContent: "space-around",
          }}
        >
          {[NodeJs,
            "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",
            ReactJs].map((res, index) => (
              <img src={res} alt="icons" className="techImage" />
            ))}
        </div>
      </div>
      <div className="caseDetailsFirstSection">
        <div className="caseDetailsFirstSectionBody">
          <span
            style={{
              fontSize: "38px",
              fontWeight: "700",
            }}
          >
            About
          </span>
          <img
            src={SAbout}
            alt="CSAbout"
            className="caseDetailsFirstSectionImage mobileHide"
          />
          <span className="caseDetailsFirstSectionText">
            SpotSaaS is a software recommendation platform where we connect
            buyers and sellers of software products. We help the software buyers
            make a better product decision based on unbiased reviews, software
            comparisons, social popularity, and buyers guide.
          </span>
        </div>
        <img
          src={SAbout}
          alt="CSAbout"
          className="caseDetailsFirstSectionImage webHide"
        />
      </div>
      <div className="caseDetailsBannerBody" style={{ margin: "10px 0" }}>
        <img
          className="caseDetailsBannerImage"
          src={SSolution}
          alt="CSSolution"
        />
      </div>
      <div className="caseDetailsProblemBody">
        <span style={{ fontSize: "38px", fontWeight: "700" }}>Problem</span>
        <span style={{ fontSize: "1rem", fontWeight: "400" }}>
          SpotSaaS is a software recommendation platform where we connect buyers
          and sellers of software products. We help the software buyers make a
          better product decision based on unbiased reviews, software
          comparisons, social popularity, and buyers guide.
        </span>
        {/* </div> */}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {problemList.map((res, index) => (
            <div className="caseDetailsProblemArray">
              <img src={Arrow} style={{ width: "40px" }} alt="Arrow" />
              <span>{res}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="caseDetailsSolutionBody">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "90%", height: "90%", borderRadius: "10px" }}
            src={SBanner}
            alt="CSBanner"
          />
        </div>
        <div className="caseDetailsTextBody">
          <span style={{ fontSize: "38px", fontWeight: "700" }}>Solution</span>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
            {solutionList.map((res, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <img src={Arrow} alt="Arrow" />
                <span>{res}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="caseDetailsImpactBody">
        <div className="caseDetailsTextBody">
          <span style={{ fontSize: "38px", fontWeight: "700" }}>impact</span>
          <img
            style={{ width: "90%", borderRadius: "10px" }}
            src={SImpact}
            alt="CSImapct"
            className="mobileHide"
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {impactList.map((res, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <img src={Arrow} alt="Arrow" />
                <span>{res}</span>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            style={{ width: "540px", height: "540px", borderRadius: "10px" }}
            src={SImpact}
            alt="CSImapct"
            className="webHide"
          />
        </div>
      </div>
      <ContactForm />
    </div>
  );
};
export default SpotsaasCaseStudy;
