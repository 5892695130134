import React from 'react'
import { AiOutlineHome } from "react-icons/ai";
import "./caseStudy.css";
import {
    WordPressIcon,
    FigmaIcon,
    CloudFrontIcon,
    Comp1,
    CSAbout,
    CSSolution,
    CSImpact,
    CSBanner,
    Arrow,
    NodeJs,
    AngularJs,
    ReactJs,
    TAbout,
    TProblem,
    TSolution,
    TImpact,
    TripeurAbout,
    TripeurImpact,
    TripeurSolution,
    TripeurProblem,
} from "../../Assets";
import ContactForm from "../Home/ContactForm";
import { div } from "@tensorflow/tfjs";
const TripeurExpenseManagementCaseStudy = () => {
    const problemList = [
        "Expense management plays a crucial role in corporate travel, and every organization has its unique approach to handle travel-related expenditures. Some issue corporate credit cards or provide cash, while others require employees to front the expenses and seek reimbursement later.",
        "Over the past six years, Tripeur has been a trusted provider of travel services. In recent years, they've been receiving requests from clients to develop an in-house expense system to streamline the management of travel-related costs. However, this endeavour posed various challenges, such as dealing with diverse types of invoices, multiple currencies, expense limits, credit card spending, and advance cash disbursements. All of these components needed to seamlessly integrate with the existing Tripeur travel system.",
        "Tripeur's objective was clear: to offer a one-click solution for uploading expense invoices, simplifying the reimbursement process and sparing users from lengthy and complicated procedures.",
    ];
    const solutionList = [
        "As part of our solution, Tripeur conducted a thorough evaluation of the 'buy versus build' approach and found that there were no existing systems offering comprehensive functionality all under one roof. Hence, Tripeur made the strategic decision to develop the system in-house.",
        "We began by creating an Invoice parser service designed to identify various aspects of invoices, such as their type, currency, expense items, and the detection of fraud and duplicate invoices. This initiative led to an impressive 89% improvement in the system's accuracy.",
        "To simplify the expense management process, we developed a mobile app that allowed users to capture invoice images and queue them for parsing. This app incorporated an image optimization algorithm to enhance the clarity of unclear invoices.",
        "We introduced automation into the system, ensuring that once all the invoices were uploaded, they were aggregated into a single location, enabling users to effortlessly generate their expense reports.",
        "Furthermore, we enriched the system with additional features, including approvals, reporting capabilities, and notifications, all aimed at enhancing the user experience and streamlining their journey.",
    ];
    const impactList = [
        "Achieved a 40% increase in adoption among existing customers within the first three months.",
        "Enhanced invoice parsing accuracy from 65% to an impressive 89%, significantly reducing the need for manual intervention in expense creation.",
        "Users consistently submitted their expense reports in an average time of approximately six minutes.",
    ];
    return (
        <div>
            {" "}
            <div className="themeColor1 caseDetails">
                <div
                    style={{
                        color: "#596080",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "1px",
                        marginTop: "20px",
                    }}
                >
                    <a href="/">
                        <AiOutlineHome />
                    </a>
                    <span>
                        <a style={{ textDecoration: 'none' }} href="/case-study">/ Case Study</a>/ Tripeur Expense Management{" "}
                    </span>{" "}
                </div>
                <img src={Comp1} style={{ height: "100px" }} alt="Company pic" />
                <span className="heading caseDetailsTittle">Tripeur's Smooth Expense Processing</span>
                <div style={{ fontSize: "20px", fontWeight: "400", color: "#171717" }}>
                    Technology Used
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "30px",
                        border: "1px solid var(--blue-10-opacity, rgba(6, 102, 174, 0.10))",
                        padding: "10px",
                        borderRadius: "20px",
                        width: "250px",
                        justifyContent: "space-around"
                    }}
                >
                    {[NodeJs,
                        ReactJs,
                        "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",].map((res, index) => (
                            <img src={res} alt="icons" className="techImage" />
                        ))}
                </div>
            </div>
            <div className="caseDetailsFirstSection">
                <div className="caseDetailsFirstSectionBody">
                    <span
                        style={{
                            fontSize: "38px",
                            fontWeight: "700",
                        }}
                    >
                        About
                    </span>
                    <img
                        src={TripeurAbout}
                        alt="TripeurAbout"
                        className="caseDetailsFirstSectionImage mobileHide"
                    />
                    <span className="caseDetailsFirstSectionText">
                        Tripeur is an end-to-end corporate travel management platform that uses
                        advanced Artificial Intelligence and Machine Learning algorithms to drive
                        efficiency, ease of use, personalization and transparency for business
                        travel by employees and executives alike.
                    </span>
                </div>

                <img
                    src={TripeurAbout}
                    alt="TripeurAbout"
                    className="caseDetailsFirstSectionImage webHide"
                />
            </div>

            <div className="caseDetailsBannerBody" style={{ margin: "10px 0" }}>
                <img
                    className="caseDetailsBannerImage"
                    src={TripeurProblem}
                    alt="TripeurProblem"
                />
            </div>
            <div className="caseDetailsProblemBody">
                <span style={{ fontSize: "38px", fontWeight: "700" }}>Problem</span>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {problemList.map((res, index) => (
                        <div className="caseDetailsProblemArray">
                            <img src={Arrow} alt="Arrow" style={{ width: "30px" }} />
                            <span>{res}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="caseDetailsSolutionBody">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <img
                        style={{ width: "90%", height: "90%", borderRadius: "10px" }}
                        src={TripeurSolution}
                        alt="TripeurSolution"
                    />
                </div>
                <div className="caseDetailsTextBody">
                    <span style={{ fontSize: "38px", fontWeight: "700" }}>solution</span>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
                        {solutionList.map((res, index) => (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                }}
                            >
                                <img src={Arrow} alt="Arrow" />
                                <span>{res}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="caseDetailsImpactBody">
                <div className="caseDetailsTextBody">
                    <span style={{ fontSize: "38px", fontWeight: "700" }}>impact</span>
                    <img
                        style={{ width: "90%", borderRadius: "10px" }}
                        src={TripeurImpact}
                        alt="TripeurImapct"
                        className="mobileHide"
                    ></img>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                        {impactList.map((res, index) => (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                }}
                            >
                                <img src={Arrow} alt="Arrow" />
                                <span>{res}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <img
                        style={{ width: "540px", height: "540px", borderRadius: "10px" }}
                        src={TripeurImpact}
                        alt="TripeurImapct"
                        className="webHide"
                    ></img>
                </div>
            </div>
            <ContactForm />
        </div>
    );
};
export default TripeurExpenseManagementCaseStudy