import React from 'react';
import styles from './home.module.css';

//Web Imports
import HeroSection from './HeroSection';
import About from './About';
import OurProcess from './OurProcess';
import Services from './Services';
import FeedBack from './FeedBack';
import OurStory from './OurStory';
import CaseStudy from './CaseStudy';
import ContactForm from './ContactForm';

//Mobile Imports 
import HeroSectionMobile from './mobile/HeroSection';
import OurMobileStory from './mobile/OurStory';
import MobileOurProcess from './mobile/mobileOurProcess';
import MobileServices from './mobile/mobileServices';



const HomePage = () => {
    return (
        <>
            <div className={styles.webHide}>
                <HeroSection />
                <About />
                <OurProcess />
                <Services />
                <FeedBack />
                <OurStory />
                <CaseStudy />
                <ContactForm />
            </div>
            <div className={styles.mobileHide}>
                <HeroSectionMobile />
                <About />
                <MobileOurProcess />
                <MobileServices />
                <FeedBack />
                <OurMobileStory />
                <CaseStudy />
                <ContactForm />
            </div>
        </>
    )
}

export default HomePage