import React from "react";

import styles from "./Blog.module.css";

import { Blog1, Blog2, Blog3, Blog4, Blog5, Blog6 } from "../../Assets";
import BlogCard from "./BlogCard";
import { AiOutlineHome } from "react-icons/ai";
import DiscussForm from "../Utils/DiscussForm";

const BlogPage = () => {
  const blogData = [
    {
      image: Blog1,
      tittle: "15 Facebook Ad Mistakes - The Advertising Errors You Must..",
      description:
        "No matter how deligently you follow Facebook ad metrics, if you are a new Facebook marketer, chances are...",
      tags: ["PPC", "PPC", "PPC"],
      time: "7",
    },
    {
      image: Blog2,
      tittle: "15 Facebook Ad Mistakes - The Advertising Errors You Must..",
      description:
        "No matter how deligently you follow Facebook ad metrics, if you are a new Facebook marketer, chances are...",
      tags: ["PPC", "PPC", "PPC"],
      time: "7",
    },
    {
      image: Blog3,
      tittle: "15 Facebook Ad Mistakes - The Advertising Errors You Must..",
      description:
        "No matter how deligently you follow Facebook ad metrics, if you are a new Facebook marketer, chances are...",
      tags: ["PPC", "PPC", "PPC"],
      time: "7",
    },
    {
      image: Blog4,
      tittle: "15 Facebook Ad Mistakes - The Advertising Errors You Must..",
      description:
        "No matter how deligently you follow Facebook ad metrics, if you are a new Facebook marketer, chances are...",
      tags: ["PPC", "PPC", "PPC"],
      time: "7",
    },
    {
      image: Blog5,
      tittle: "15 Facebook Ad Mistakes - The Advertising Errors You Must..",
      description:
        "No matter how deligently you follow Facebook ad metrics, if you are a new Facebook marketer, chances are...",
      tags: ["PPC", "PPC", "PPC"],
      time: "7",
    },
    {
      image: Blog6,
      tittle: "15 Facebook Ad Mistakes - The Advertising Errors You Must..",
      description:
        "No matter how deligently you follow Facebook ad metrics, if you are a new Facebook marketer, chances are...",
      tags: ["PPC", "PPC", "PPC"],
      time: "7",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width:"100%"
      }}
    >
      {/* <div className="themeColor1 blogTittle"> */}
      <div className={`${styles.blogTittle} themeColor1`}>
        <div
          style={{
            color: "#596080",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1px",
          }}
        >
          <AiOutlineHome /> <span> / Our Blogs </span>{" "}
        </div>
        <span className="heading">Our Blogs</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          padding: "5%",
        }}
        className={styles.blogCardParent}
      >
        {blogData.map((res) => (
          <BlogCard blogData={res} />
        ))}
      </div>

      <DiscussForm />
    </div>
  );
};

export default BlogPage;
