import React from "react";
import { Founder, StoryImage, Team1, Team2 } from "../../../Assets";

const OurStory = () => {
  return (
    <div
      className="themeColor1"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "5%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "95%",
          marginTop: "5%",
        }}
      >
        <span style={{ margin: "20px 0", fontSize: "38px", fontWeight: "600" }}>
          Our <span style={{ color: "#0078D1", fontWeight: "700" }}>Story</span>
        </span>
        <div style={{ display: "flex", width: "100%",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:"10px" }}>
          <img
            src={Founder}
            style={{ width: "100%", borderRadius: "10px", zIndex: "9" }}
            alt="founderImage"
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap:"10px",
              zIndex: "1px",
            }}
          >
            <img
              src={Team1}
              style={{ width: "48%", borderRadius: "10px" }}
              alt="founderImage"
            />
            <img
              src={Team2}
              style={{ width: "48%", borderRadius: "10px" }}
              alt="founderImage"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "95%",
          marginTop: "5%",
        }}
      >
        <span style={{ fontSize: "18px", fontWeight: "400", color: "#596080" }}>
          The name Eternalight is derived from Amardeep, an Indian name meaning
          Eternal and Light. Inspired by my father's successful ice cream shop
          established 30 years ago, I aimed for a similar prosperity in my
          venture, thus adopting the English version of the name.
        </span>
        <span
          style={{
            fontSize: "18px",
            fontWeight: "400",
            color: "#596080",
            marginTop: "10px",
          }}
        >
          Commencing my engineering journey in 2014 as an intern in a 5-person
          startup, I harbored a vision to establish a successful company and
          contribute to the community. Over the past nine years, my professional
          trajectory encompassed roles from a junior engineer to an engineering
          manager, collaborating with startups ranging from 10 to 3000+ people.
          Having experienced the spectrum of delivering both failed and
          successful software projects, I reached a pivotal moment when I
          decided it was time to initiate my own venture.
        </span>
        <span
          style={{
            fontSize: "18px",
            fontWeight: "400",
            color: "#596080",
            marginTop: "10px",
          }}
        >
          The inception of our software company stems from the desire to
          innovate and create superior software solutions for the world.
          Recognizing the challenges individuals face in finding the right
          technology partner for their ideas, I set out to build a company that
          not only excels in software development but prioritizes scalability
          and innovation. With the support of 10+ folks in my company who share
          the vision of building innovative and scalable solutions, we embark on
          this mission together.
        </span>
      </div>
    </div>
  );
};

export default OurStory;
