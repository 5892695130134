import React from "react";
import styles from "./Blog.module.css";

const BlogCard = ({ blogData }) => {
  return (
    <div className={styles.blogCard} style={{cursor:"pointer"}}>
      <img
        style={{ width: "100%", borderRadius: "10px 10px 0 0" }}
        src={blogData.image}
        alt="blogData"
      />
      <div
        style={{
          padding: "5%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          background: "#ffff",
          borderRadius: "0 0 10px 10px",
        }}
      >
        <span style={{ fontSize: "22px", fontWeight: "600", color: "#171717" }}>
          {blogData.tittle}
        </span>
        <span style={{ fontSize: "16px", fontWeight: "400", color: "#596080" }}>
          {blogData.description}
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          {blogData.tags.map((res) => (
            <span
              style={{
                background: "rgba(255, 164, 56, 0.20)",
                borderRadius: "10px",
                width: "50px",
                textAlign: "center",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {res}
            </span>
          ))}
        </div>
        <span style={{ fontSize: "14px", fontWeight: "400", color: "#596080" }}>
          {blogData.time} min read
        </span>
      </div>
    </div>
  );
};

export default BlogCard;
