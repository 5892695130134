import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "../Home/home.module.css";
import { Arrow1 } from "../../Assets";

const CaseStudyCard = ({ caseStudyData }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (
      caseStudyData?.tittle === "Tripeur" ||
      caseStudyData?.tittle === "Xetlink" ||
      caseStudyData?.tittle === "Spotsaas" ||
      caseStudyData?.tittle === "Tripeur GST Reconcile" ||
      caseStudyData?.tittle === "Tripeur Expense Management"
    ) {
      window.location.href = `/case-study/${caseStudyData.tittle}`;
    }
  };

  return (
    <div
      style={{
        border: "1.5px solid var(--blue-10-opacity, rgba(6, 102, 174, 0.10))",
        cursor:
          caseStudyData?.tittle === "Tripeur" ||
            caseStudyData?.tittle === "Xetlink" ||
            caseStudyData?.tittle === "Spotsaas" ||
            caseStudyData?.tittle === "Tripeur GST Reconcile" ||
            caseStudyData?.tittle === "Tripeur Expense Management"
            ? "pointer"
            : "not-allowed",
        boxShadow: "1px 1px 4px 0px #888888",
      }}
      className={Styles.caseStudyScreen}
      onClick={() => handleClick()}
    >
      {caseStudyData.type === "image" && (
        <img
          className={Styles.caseStudyImage}
          src={caseStudyData.image}
          alt="CaseStudyImage"
        />
      )}
      {caseStudyData.type === "video" && (
        <video
          className={`${Styles.aboutVideo} ${Styles.caseStudyImage}`}
          // ref={videoRef}
          loop
          autoPlay
          muted
          playsInline
        >
          <source src={caseStudyData.image} type="video/mp4" />
        </video>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          padding: "2% 0",
          width: "100%",
        }}
      >
        <span style={{ fontSize: "30px", fontWeight: "700" }}>
          {" "}
          {caseStudyData?.tittle}
        </span>
        <span style={{ fontSize: "16px", fontWeight: "400", width: "95%" }}>
          {" "}
          {caseStudyData?.description}
        </span>
        <span style={{ fontSize: "16px", fontWeight: "700", color: "#0078D1" }}>
          {"Read more"} <img src={Arrow1} alt="arrowimg" style={{width: "24px", height:"14px"}}></img>
       </span>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          {caseStudyData?.section.map((res, index) => (
            <div
              key={index}
              style={{
                borderRadius: "10px",
                color: "#171717",
                border: "1px solid #DEDEDE",
                fontSize: "14px",
                fontWeight: "400",
                padding: "10px",
              }}
            >
              {res}
            </div>
          ))}
        </div>
        <span style={{ fontSize: "16px", fontWeight: "400" }}> Technology</span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            border: "1px solid var(--blue-10-opacity, rgba(6, 102, 174, 0.10))",
            padding: "10px",
            borderRadius: "20px",
            width: "200px",
            height: "30px",
            justifyContent: "space-around",
          }}
        >
          {caseStudyData.technologies.map((res, index) => (
            <img src={res} style={{ objectFit: "contain" }} alt="icons" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CaseStudyCard;
